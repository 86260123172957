.companies-pop-modal-dialog .modal-dialog {
    font-family: "AileronRegular";
    width: 26vw;
    height: 50%;
    max-width: 100%;
}

.companies-header {
    border-bottom: none;
}

.companies-body a {
    color: black;
    text-decoration: none;
}

.companies-imgthumbnail {
    padding: 0.25rem;
}

.companies-f {
    min-height: 370px;
    background-color: white;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.companies-card {
    margin: 0 0 30px 0;
}

.companies-card a {
    color: black;
    text-decoration: none;
}

.companies-mr1 {
    margin-right: .25rem !important;
}


.companies-fxlayout1 {
    flex-direction: column;
    box-sizing: border-box;
    display: flex;
    place-content: center space-between;
    align-items: center;
}

.companies-tag {
    font-size: 11px;
    color: black;
    border-radius: 2px;
    padding: 5px 1px;
}

.companies-tag>a {
    color: black;
    text-align: center;
}

.companies-logo {
    width: 100%;
}

.companies-logobox {
    -webkit-box-shadow: 2px 2px 5px 0px rgba(0, 0, 0, 0.69);
    -moz-box-shadow: 2px 2px 5px 0px rgba(0, 0, 0, 0.69);
    box-shadow: 2px 2px 5px 0px rgba(0, 0, 0, 0.69);
    width: 80px;
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 5px;
}

.companies-name {
    float: left;
    padding: 10px 10px 0px 10px;
    margin: -60px 0 0 85px;
    width: 85%;
    height: 80px;
}

.companies-name>h3 {
    color: black;
    font-size: 20px;
    font-weight: bold;
    margin: 0;
    padding: 0;
    overflow: hidden;
    text-overflow: ellipsis !important;
    font-family: "AileronRegular";
}

.companies-name2 {
    float: left;
    color: black;
    padding: 10px 10px 0px 5px;
    margin: -30px 0 0 0;
    width: 100%;
    height: 85px;
}

.companies-name2>p {
    padding: 5px 0 0 0;
    margin: 0;
    max-height: 100%;
    overflow: hidden;
    text-overflow: ellipsis !important;
    font-size: 16px;
    font-family: "AileronRegular";
}

.companies-products {
    display: flex;
    align-items: center;
    justify-content: center;
}

.companies-products>a {
    display: inline-block;
    overflow: hidden;
    max-width: 100%;
    text-align: center;
}

.companies-products>a>img {
    transition: transform 0.15s ease;
    width: 100%;
    /* height: 150px; */
}

.companies-products>a>img:hover {
    transform: scale(1.1, 1.1);
}

.companies-bottom {
    height: fit-content;
    width: 100%;
    /* padding: fit-content;
    margin-top: 20px;
    margin-right: -30px; */
}

.companies-bottom>.indextag {
    text-align: center;
    margin: 3px 0 0 0;
}

.companies-bottom>.indextag>a {
    text-align: center;
}

.companies-location {
    position: absolute;
    right: 10px;
}

.companies-location>a>.companies-tag {
    font-size: 11px;
    color: #039be5;
    border: 1px solid lightgray;
    border-radius: 2px;
    padding: 0 1px;
    margin: 2px;
    font-family: "AileronRegular" !important;
}

.companies-location>a {
    color: #333;
}

.companies-location>a:hover {
    color: blue;
    text-decoration: none;
}

.companies-description {
    position: relative;
    overflow: hidden;
    width: 100%;
    height: 125px;
    padding: 2px 0 0 0;
}

/* .fxlayout-row */
.companies-fxlayout5 {
    flex-direction: row;
    box-sizing: border-box;
    display: flex;
}

.companies-leftheader {
    float: left;
    width: 100%;
    padding: 0px 0 5px 6px;
    font-family: "AileronRegular";
}

.companies-reasonsection {
    position: relative;
    overflow: hidden;
    width: 100%;
    height: 125px;
    /* height: 100%;  */
    padding: 2px 0 0 0;
}

.companies-rightheader {
    float: right;
    text-align: right;
    width: 50%;
    height: 10%;
    padding: 7px 0 0 0;
}

.companies-reason {
    width: 50%;
    height: 45px;
    float: left;
    text-align: center;
}

.companies-reasonbutton {
    text-align: center !important;
    border-radius: 5px;
    color: white !important;
    background-color: #ef3b23;
    width: 95%;
    text-overflow: ellipsis !important;
    overflow: hidden;
    white-space: nowrap;
    display: inline-block;
    margin: 4px;
    font-family: "AileronRegular";
    font-size: 14px;
    font-weight: bold;
    height: 82%;
    padding: 4% 8%;
}

.companies-reasonbutton:hover {
    background: #f26553;
    text-decoration: none;
}

.companies-flagicon {
    margin-right: 5px;
    background-size: contain;
    background-position: 50%;
    background-repeat: no-repeat;
}

.companies-industry {
    border: 2px solid green;
    background-color: #fff;
    margin: 3px;
    width: 30%;
    overflow: hidden;
    white-space: nowrap;
    display: inline-block;
    font-family: "AileronRegular";
    border-radius: 15px;
    font-size: 11px;
    text-overflow: ellipsis !important;
}

.companies-industrybutton, .companies-categorybutton {
    padding: 0 0.75rem !important;
}

.companies-industrybutton {
    border: 2px solid green;
    margin: 3px;
    width: 31%;
    text-overflow: ellipsis !important;
    overflow: hidden;
    white-space: nowrap;
    display: inline-block;
    font-family: "AileronRegular";
    border-radius: 15px;
    color: black;
}

.companies-industry:hover {
    border: 2px solid green;
    background-color: green;
    color: #fff;
}

.companies-industrybutton:hover {
    background-color: green;
    color: white;
    text-decoration: none;
}

.companies-categorybutton {
    border: 2px solid #76c4eb;
    margin: 3px;
    width: 31%;
    text-overflow: ellipsis !important;
    overflow: hidden;
    white-space: nowrap;
    display: inline-block;
    font-family: "AileronRegular";
    border-radius: 15px;
    color: black;
}

.companies-categorybutton:hover {
    background-color: #76c4eb;
    color: white;
    text-decoration: none;
}

.companies-categories:hover {
    border: 2px solid #76c4eb;
    background-color: #76c4eb;
    color: #fff;
}

.companies-cardrow {
    position: relative;
    width: 100%;
}

/* max-width */

@media only screen and (max-width: 320px) {
    .companies-tag {
        font-size: 8px !important;
    }

    .companies-industrybutton, .companies-categorybutton {
        width: 30%;
    }

    .companies-reasonbutton {
        height: auto;
    }
}

@media only screen and (max-width: 600px) {
    .companies-bottom {
        height: fit-content;
    }
}

@media only screen and (max-width: 640px) {
    .indexcompanycard {
        padding: 24px 0 0 0;
    }
}
.prerevmethods {
    padding: 0 15%;
}

.prerevmethodsaccordioncardbody {
    background: #f7f7f7;
    padding: 0 !important;
    overflow: auto;
}

.prerevmethodstable {
    width: 100%;
}

.prerevmethodscard {
    border: none !important;
}

.prerevmethodstable table {
    table-layout: fixed;
    width: 100%;
}

.prerevmethodstable tr {
    height: 100px;
}

.prerevmethodstable td {
    text-align: center;
    white-space: nowrap;
    padding: 8px;
}

.prerevmethodstable td {
    padding: 15px;
    width: 50%;
}

.prerevmethodsrow {
    padding-bottom: 20px;
    display: flex;
    flex-direction: row;
}

.prerevmethodsrow2 {
    padding-bottom: 20px;
    width: 100%;
}

.prerevmethodsfield {
    width: 33%;
}

.prerevmethodsfield2 {
    width: 50%;
}

.prerevmethodsinput {
    margin-bottom: 1rem;
}

.prerevmethodsheader {
    color: #1f5f8b !important;
    font-weight: bold;
    font-size: 18px;
    padding-top: 2rem;
    padding-bottom: 1rem;
}

.prerevmethodsheader:first-child {
    color: #1f5f8b !important;
    font-weight: bold;
    font-size: 18px;
    padding-top: 0;
    padding-bottom: 1rem;
}

.prerevmethodsdivnotes {
    padding: 2% 5%;
}

.prerevmethodsnotes {
    font-size: 14px;
    font-family: "AileronRegular";
}

.prerevmethodsnotes li {
    margin-bottom: 0.5rem;
}

.prerevmethodsnotes a {
    color: #039be5 !important;
}

.prerevmethodsnotes a:hover {
    color: #039be5 !important;
    text-decoration: underline;
}

.prerevmethodsnotes li:hover {
    background: none;
}

.prerevmethodsdivfilter {
    padding-top: 1%;
    padding-bottom: 2%;
}

.prerevmethodstextmuted {
    font-family: "AileronRegular";
    font-size: 10.5px;
    padding: 0 1em;
}

.prerevmethodsdivbuttons {
    place-content: stretch center;
    align-items: stretch;
    flex-direction: row;
    box-sizing: border-box;
    display: flex;
    margin-top: 1.5rem;
    background-color: transparent !important;
}

.prerevmethodsresetbutton {
    padding: 5px 25px;
    background: transparent;
    outline: none !important;
    cursor: pointer;
    transition: all 0.3s ease;
    position: relative;
    display: inline-block;
    width: 90px;
    height: 35px;
    line-height: 35px;
    padding: 0;
    border: none;
    margin-right: 10px;
}

.prerevmethodsresetbutton span {
    position: relative;
    display: block;
    width: 100%;
    height: 100%;
    font-family: "AileronRegular";
    font-size: 14px;
    font-weight: bold;
}

.prerevmethodsresetbutton:before, .prerevmethodsresetbutton:after {
    position: absolute;
    content: "";
    height: 0%;
    width: 2px;
    background: gainsboro;
}

.prerevmethodsresetbutton:before {
    right: 0;
    top: 0;
    transition: all 0.1s ease;
}

.prerevmethodsresetbutton:after {
    left: 0;
    bottom: 0;
    transition: all 0.1s 0.2s ease;
}

.prerevmethodsresetbutton:hover:before {
    transition: all 0.1s 0.2s ease;
    height: 100%;
}

.prerevmethodsresetbutton:hover:after {
    transition: all 0.1s ease;
    height: 100%;
}

.prerevmethodsresetbutton span:before, .prerevmethodsresetbutton span:after {
    position: absolute;
    content: "";
    background: gainsboro;
}

.prerevmethodsresetbutton span:before {
    left: 0;
    top: 0;
    width: 0%;
    height: 2px;
    transition: all 0.1s 0.1s ease;
}

.prerevmethodsresetbutton span:after {
    left: 0;
    bottom: 0;
    width: 100%;
    height: 2px;
}

.prerevmethodsresetbutton span:hover:before {
    width: 100%;
}

.prerevmethodssubmitbutton {
    color: black !important;
    padding: 5px 25px !important;
    font-family: "AileronRegular";
    font-size: 14px !important;
    font-weight: bold !important;
    background: transparent !important;
    outline: none !important;
    cursor: pointer !important;
    transition: all 0.3s ease !important;
    position: relative !important;
    display: inline-block !important;
    border: 2px solid gainsboro !important;
    border-radius: 0 !important;
    z-index: 1 !important;
    margin-left: 10px !important;
}

.prerevmethodssubmitbutton:after {
    position: absolute;
    content: "";
    width: 0;
    height: 100%;
    top: 0;
    right: 0;
    z-index: -1;
    background: gainsboro;
    transition: all 0.3s ease;
}

.prerevmethodssubmitbutton:hover:after {
    left: 0;
    width: 100%;
}

.prerevmethodssubmitbutton:active {
    top: 2px;
}

.prerevmethodshistorytable {
    border-collapse: collapse;
    width: 100%;
}

.prerevmethodshistorytable > thead {
    border-collapse: collapse;
    width: 100%;
    text-align: center;
}

.prerevmethodshistorytable > td:first-child {
    width: 50%;
}

.prerevmethodstablerow { 
    border: 1px solid #dee2e6;
    border-width: 1px 0;
    height: 50px;
    font-size: 16px;
}
  
.prerevmethodstablerow:last-child {
    border-bottom: none;
}

.prerevmethodstabledata {
    width: 50vw;
    padding: 1rem 10px;
}

@media (max-width: 425px) {
    .prerevmethods {
        padding: 0%;
    }
}

@media (max-width: 768px) {
    .prerevmethodsrow {
        padding-bottom: 0;
        flex-direction: column;
        width: 100%;
    }
  
    .prerevmethodsfield, .prerevmethodsfield2 {
        width: 100%;
        padding-bottom: 20px;
        padding-right: 0 !important;
    }
}
.accordion-card-body {
    height: 80vh !important;
    background: #f7f7f7;
    padding: 0 !important;
    overflow: auto;
}

.div-filter {
    padding-top: 1%;
    padding-bottom: 2%;
}

.Submenu_submenu__1GEnq {
    left: 100%;
    width: fit-content;
}

.card-holder {
    height: 100%;
}

.vis-cards {
    min-height: 100%;
    min-width: 100%;
}

.form-row>.col,
.form-row>[class*=col-] {
    padding: 0 5px 10px 0;
}

.form-control {
    font-family: "AileronRegular";
    font-size: 14px !important;
}

.submit-button {
    padding: 5px 25px;
    font-family: "AileronRegular";
    font-size: 14px;
    font-weight: bold;
    background: transparent;
    outline: none !important;
    cursor: pointer;
    transition: all 0.3s ease;
    position: relative;
    display: inline-block;
    border: 2px solid gainsboro;
    z-index: 1;
}

.submit-button:after {
    position: absolute;
    content: "";
    width: 0;
    height: 100%;
    top: 0;
    right: 0;
    z-index: -1;
    background: gainsboro;
    transition: all 0.3s ease;
}

.submit-button:hover:after {
    left: 0;
    width: 100%;
}

.submit-button:active {
    top: 2px;
}

.reset-button {
    padding: 5px 25px;
    background: transparent;
    outline: none !important;
    cursor: pointer;
    transition: all 0.3s ease;
    position: relative;
    display: inline-block;
    width: 90px;
    height: 35px;
    line-height: 35px;
    padding: 0;
    border: none;
}

.reset-button span {
    position: relative;
    display: block;
    width: 100%;
    height: 100%;
    font-family: "AileronRegular";
    font-size: 14px;
    font-weight: bold;
}

.reset-button:before,
.reset-button:after {
    position: absolute;
    content: "";
    height: 0%;
    width: 2px;
    background: gainsboro;
}

.reset-button:before {
    right: 0;
    top: 0;
    transition: all 0.1s ease;
}

.reset-button:after {
    left: 0;
    bottom: 0;
    transition: all 0.1s 0.2s ease;
}

.reset-button:hover:before {
    transition: all 0.1s 0.2s ease;
    height: 100%;
}

.reset-button:hover:after {
    transition: all 0.1s ease;
    height: 100%;
}

.reset-button span:before,
.reset-button span:after {
    position: absolute;
    content: "";
    background: gainsboro;
}

.reset-button span:before {
    left: 0;
    top: 0;
    width: 0%;
    height: 2px;
    transition: all 0.1s 0.1s ease;
}

.reset-button span:after {
    left: 0;
    bottom: 0;
    width: 100%;
    height: 2px;
}

.reset-button span:hover:before {
    width: 100%;
}

.extra-notes {
    padding: 2% 5%;
}

.info-text {
    font-size: 12px;
    font-family: "AileronRegular";
    font-style: italic;
}

.info-notes {
    font-size: 14px;
    font-family: "AileronRegular";
}

#notes-points:hover {
    background: none;
}

.graph-card {
    border: none !important;
}

#vc-charts {
    width: 1200px;
    height: 500px;
}

#vc-chartsxs {
    width: 900px;
    height: 500px;
}

.recharts-wrapper {
    height: 100% !important;
    width: 100% !important;
    font-family: AileronRegular !important;
}

.recharts-surface {
    height: 100% !important;
    width: 100% !important;
}

.error-message {
    font-size: 13px;
    font-weight: bold;
    font-family: "AileronRegular";
}

.error-message a,
.error-message a:hover {
    color: #039be5 !important;
}

.floating-alert {
    bottom: 10vh;
    left: 5vw;
    width: 30vw;
    z-index: 2;
    position: fixed !important;
    box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.5);
}

.floating-alert a {
    color: #039be5 !important;
}

.floating-alert a:hover {
    color: #039be5 !important;
    text-decoration: underline;
}

.alert-icon {
    width: 1.5em;
    height: 1.5em;
    margin-right: 5px;
}

.alert-cta,
.alert-cta:hover {
    color: #039be5 !important;
    text-decoration: underline;
}

.progressbardivfilters {
    padding-bottom: 1.25rem;
}

.progressbardivfilters>div {
    height: 0.3rem;
    background-color: white;
    border-radius: .35rem;
}

@media only screen and (max-width: 1440px) {
    .floating-alert {
        width: 40vw !important;
    }
}

@media only screen and (max-width: 768px) {
    .floating-alert {
        width: 80vw !important;
    }
}

@media only screen and (max-width: 425px) {
    #vc-charts {
        width: 1000px !important;
        height: 500px !important;
    }

    #vc-chartsxs {
        width: 400px !important;
        height: 500px !important;
    }

    .form-control {
        font-size: 12px !important;
    }

    .reset-button {
        font-size: 12px !important;
    }

    .submit-button {
        font-size: 12px !important;
    }
}

@media only screen and (min-width: 426px) and (max-width: 576px) {
    #vc-charts {
        width: 1100px !important;
        height: 500px !important;
    }

    #vc-chartsxs {
        width: 400px !important;
        height: 500px !important;
    }
}

@media only screen and (min-width: 577px) and (max-width: 768px) {
    #vc-charts {
        width: 1100px !important;
        height: 500px !important;
    }

    #vc-chartsxs {
        width: 600px !important;
        height: 500px !important;
    }
}

@media only screen and (min-width: 769px) and (max-width: 1024px) {
    #vc-charts {
        width: 1200px !important;
        height: 500px !important;
    }

    #vc-chartsxs {
        width: 800px !important;
        height: 500px !important;
    }
}

@media only screen and (min-width: 1025px) and (max-width: 1440px) {
    #vc-charts {
        width: 1200px !important;
        height: 500px !important;
    }

    #vc-chartsxs {
        width: 800px !important;
        height: 500px !important;
    }
}

@media only screen and (min-width: 1441px) {
    #vc-charts {
        width: 1200px !important;
        height: 500px !important;
    }

    #vc-chartsxs {
        width: 900px !important;
        height: 500px !important;
    }
}
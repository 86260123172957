.marketsizingversions {
    padding: 0 15%;
}

.marketsizingversionsaccordioncardbody {
    background: #f7f7f7;
    padding: 0 !important;
    overflow: auto;
}

.marketsizingversionstable {
    width: 100%;
}

.marketsizingversionscard {
    border: none !important;
}

.marketsizingversionstable table {
    table-layout: fixed;
    width: 100%;
}

.marketsizingversionstable tr {
    height: 100px;
}

.marketsizingversionstable td {
    text-align: center;
    white-space: nowrap;
    padding: 8px;
}

.marketsizingversionstable td {
    padding: 15px;
    width: 50%;
}

.marketsizingversionsrow {
    padding-bottom: 20px;
    display: flex;
    flex-direction: row;
}

.marketsizingversionsrow2 {
    padding-bottom: 20px;
    width: 100%;
}

.marketsizingversionsfield {
    width: 33%;
}

.marketsizingversionsfield2 {
    width: 50%;
}

.marketsizingversionsinput {
    margin-bottom: 1rem;
}

.marketsizingversionsheader {
    color: #1f5f8b !important;
    font-weight: bold;
    font-size: 18px;
    padding-top: 2rem;
    padding-bottom: 1rem;
}

.marketsizingversionsheader:first-child {
    color: #1f5f8b !important;
    font-weight: bold;
    font-size: 18px;
    padding-top: 0;
    padding-bottom: 1rem;
}

.marketsizingversionsdivnotes {
    padding: 2% 5%;
}

.marketsizingversionsnotes {
    font-size: 14px;
    font-family: "AileronRegular";
}

.marketsizingversionsnotes li {
    margin-bottom: 0.5rem;
}

.marketsizingversionsnotes a {
    color: #039be5 !important;
}

.marketsizingversionsnotes a:hover {
    color: #039be5 !important;
    text-decoration: underline;
}

.marketsizingversionsnotes li:hover {
    background: none;
}

.marketsizingversionsdivfilter {
    padding-top: 1%;
    padding-bottom: 2%;
}

.marketsizingversionstextmuted {
    font-family: "AileronRegular";
    font-size: 10.5px;
    padding: 0 1em;
}

.marketsizingversionsdivbuttons {
    place-content: stretch center;
    align-items: stretch;
    flex-direction: row;
    box-sizing: border-box;
    display: flex;
    margin-top: 1.5rem;
    background-color: transparent !important;
}

.marketsizingversionsresetbutton {
    padding: 5px 25px;
    background: transparent;
    outline: none !important;
    cursor: pointer;
    transition: all 0.3s ease;
    position: relative;
    display: inline-block;
    width: 90px;
    height: 35px;
    line-height: 35px;
    padding: 0;
    border: none;
    margin-right: 10px;
}

.marketsizingversionsresetbutton span {
    position: relative;
    display: block;
    width: 100%;
    height: 100%;
    font-family: "AileronRegular";
    font-size: 14px;
    font-weight: bold;
}

.marketsizingversionsresetbutton:before, .marketsizingversionsresetbutton:after {
    position: absolute;
    content: "";
    height: 0%;
    width: 2px;
    background: gainsboro;
}

.marketsizingversionsresetbutton:before {
    right: 0;
    top: 0;
    transition: all 0.1s ease;
}

.marketsizingversionsresetbutton:after {
    left: 0;
    bottom: 0;
    transition: all 0.1s 0.2s ease;
}

.marketsizingversionsresetbutton:hover:before {
    transition: all 0.1s 0.2s ease;
    height: 100%;
}

.marketsizingversionsresetbutton:hover:after {
    transition: all 0.1s ease;
    height: 100%;
}

.marketsizingversionsresetbutton span:before, .marketsizingversionsresetbutton span:after {
    position: absolute;
    content: "";
    background: gainsboro;
}

.marketsizingversionsresetbutton span:before {
    left: 0;
    top: 0;
    width: 0%;
    height: 2px;
    transition: all 0.1s 0.1s ease;
}

.marketsizingversionsresetbutton span:after {
    left: 0;
    bottom: 0;
    width: 100%;
    height: 2px;
}

.marketsizingversionsresetbutton span:hover:before {
    width: 100%;
}

.marketsizingversionssubmitbutton {
    color: black !important;
    padding: 5px 25px !important;
    font-family: "AileronRegular";
    font-size: 14px !important;
    font-weight: bold !important;
    background: transparent !important;
    outline: none !important;
    cursor: pointer !important;
    transition: all 0.3s ease !important;
    position: relative !important;
    display: inline-block !important;
    border: 2px solid gainsboro !important;
    border-radius: 0 !important;
    z-index: 1 !important;
    margin-left: 10px !important;
}

.marketsizingversionssubmitbutton:after {
    position: absolute;
    content: "";
    width: 0;
    height: 100%;
    top: 0;
    right: 0;
    z-index: -1;
    background: gainsboro;
    transition: all 0.3s ease;
}

.marketsizingversionssubmitbutton:hover:after {
    left: 0;
    width: 100%;
}

.marketsizingversionssubmitbutton:active {
    top: 2px;
}

/* form table */

.marketsizingversionshistorytable {
    border-collapse: collapse;
    width: 100%;
}

.marketsizingversionshistorytable > thead {
    border-collapse: collapse;
    width: 100%;
    text-align: center;
}

.marketsizingversionshistorytable > td:first-child {
    width: 50%;
}

.marketsizingversionstablerow { 
    border: 1px solid #dee2e6;
    border-width: 1px 0;
    height: 50px;
    font-size: 16px;
}
  
.marketsizingversionstablerow:last-child {
    border-bottom: none;
}

.marketsizingversionstabledata {
    width: 50vw;
    padding: 1rem 10px;
}

/* results table */

.marketsizingversionstablerowresult { 
    border: 1px solid #dee2e6;
    border-width: 1px 0;
    height: 100px;
    font-size: 16px;
}
  
/* .marketsizingversionstablerowresult:last-child {
    border-bottom: none;
} */

.marketsizingversionstabledataresult {
    width: 50vw;
    padding: 1rem 10px;
    text-align: center;
    border-right: 1px solid #dee2e6;
}

.marketsizingversionstabledataresultlast {
    width: 50vw;
    padding: 1rem 10px;
    text-align: center;
    border-right: none;
}

@media (max-width: 425px) {
    .marketsizingversions {
        padding: 0%;
    }
}

@media (max-width: 768px) {
    .marketsizingversionsrow {
        padding-bottom: 0;
        flex-direction: column;
        width: 100%;
    }
  
    .marketsizingversionsfield, .marketsizingversionsfield2 {
        width: 100%;
        padding-bottom: 20px;
        padding-right: 0 !important;
    }
}
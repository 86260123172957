.sort-navbardropdownitem {
    font-size: 16px !important;
    font-family: "AileronRegular";
    color: #16181b !important;
}

.sort-navbardropdownitem:hover {
    color: #16181b !important;
    text-decoration: none !important;
}

.sort-navbardropdown>.nav-link {
    padding: 0rem !important;
}

.sort-navbardropdown .dropdown-toggle:after {
    content: none !important;
}

.sort-navbardropdown .dropdown-menu {
    width: max-content !important;
}

.sort-navbaricon {
    height: 1.4em;
    width: 1.4em;
    color: slategrey;
}

#sort-navbaractive:hover {
    background-color: #edeff2;
    height: 100%;
    text-decoration: underline;
}
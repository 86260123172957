#shutdowndata {
    width: 100%;
    background-color: #eff8ff;
    min-height: 100%;
    position: relative;
    font-family: "AileronRegular";
}

.shutdowndataheaderdiv {
    width: 100%;
    display: flex;
    flex-direction: row;
}

.shutdowndatatitlediv {
    width: 70%;
    padding: 50px;
}

.shutdowndatastatsdiv {
    width: 30%;
    padding: 50px;
}

.shutdowndatalink {
    color: #039be5 !important;
}

.shutdowndatalink:hover {
    color: #039be5 !important;
    text-decoration: underline;
    cursor: pointer;
}

.shutdowndataeditbutton {
    background-color: transparent !important;
    border: none !important;
}

.shutdowndataeditbutton:hover {
    background-color: gainsboro !important;
    border: none !important;
}

.shutdowndataedit {
    color: slategrey !important;
}

.shutdowndataedit:hover {
    color: slategrey !important;
}

.shutdowndatatext {
    font-family: "AileronRegular";
    text-align: left;
    font-size: 28px;
    font-weight: bold;
}

.shutdowndatasearchdiv {
    width: 100%;
    display: flex;
    flex-direction: row;
}

.shutdowndatasearchbox {
    width: 30%;
    padding: 20px 10px 20px 50px;
}

.shutdowndatasearchbuttons {
    width: 30%;
    padding: 20px 50px 20px 10px;
}

.shutdowndatadownloadbuttons {
    width: 40%;
    padding: 20px 50px 20px 10px;
    text-align: end;
}


/* hide field button */

.shutdowndatahidefieldbuttons {
    width: 40%;
}

.shutdowndatahidefieldbutton {
    height: 42px;
    color: black !important;
    padding: 10px 5px 5px 5px !important;
    font-family: "AileronRegular";
    font-size: 15px !important;
    font-weight: bold !important;
    background: none !important;
    outline: none !important;
    cursor: pointer !important;
    transition: all 0.3s ease !important;
    position: relative !important;
    display: inline-block !important;
    border: none ! important;
    z-index: 1 !important;
    margin: 20px 20px 5px 390px !important;
}

.shutdowndatahidefieldbutton:hover {
    text-decoration: underline;
}

.shutdownellipsisicon {
    color: black !important;
    font-family: "AileronRegular";
    font-size: 15px !important;
    transition: all 0.3s ease !important;
    position: relative !important;
    display: inline-block !important;
    border: none ! important;
    padding: 0.5em !important;
}

.shutdowndataotherbuttons {
    width: 40%;
    padding: 20px 50px 20px 10px;
    text-align: right;
}

.shutdowndataresetbutton {
    padding: 5px 25px;
    background: transparent;
    outline: none !important;
    cursor: pointer;
    transition: all 0.3s ease;
    position: relative;
    display: inline-block;
    width: 90px;
    height: 42px;
    line-height: 40px;
    padding: 0;
    border: none;
    margin-right: 10px;
}

.shutdowndataresetbutton span {
    position: relative;
    display: block;
    width: 100%;
    height: 100%;
    font-family: "AileronRegular";
    font-size: 14px;
    font-weight: bold;
}

.shutdowndataresetbutton:before,
.shutdowndataresetbutton:after {
    position: absolute;
    content: "";
    height: 0%;
    width: 2px;
    background: gainsboro;
}

.shutdowndataresetbutton:before {
    right: 0;
    top: 0;
    transition: all 0.1s ease;
}

.shutdowndataresetbutton:after {
    left: 0;
    bottom: 0;
    transition: all 0.1s 0.2s ease;
}

.shutdowndataresetbutton:hover:before {
    transition: all 0.1s 0.2s ease;
    height: 100%;
}

.shutdowndataresetbutton:hover:after {
    transition: all 0.1s ease;
    height: 100%;
}

.shutdowndataresetbutton span:before,
.shutdowndataresetbutton span:after {
    position: absolute;
    content: "";
    background: gainsboro;
}

.shutdowndataresetbutton span:before {
    left: 0;
    top: 0;
    width: 0%;
    height: 2px;
    transition: all 0.1s 0.1s ease;
}

.shutdowndataresetbutton span:after {
    left: 0;
    bottom: 0;
    width: 100%;
    height: 2px;
}

.shutdowndataresetbutton span:hover:before {
    width: 100%;
}

.shutdowndatasearchbutton {
    height: 42px;
    color: black !important;
    padding: 5px 25px !important;
    font-family: "AileronRegular";
    font-size: 14px !important;
    font-weight: bold !important;
    background: transparent !important;
    outline: none !important;
    cursor: pointer !important;
    transition: all 0.3s ease !important;
    position: relative !important;
    display: inline-block !important;
    border: 2px solid gainsboro !important;
    border-radius: 0 !important;
    z-index: 1 !important;
    margin-left: 10px !important;
}

.shutdowndatasearchbutton:after {
    position: absolute;
    content: "";
    width: 0;
    height: 100%;
    top: 0;
    left: 0;
    direction: rtl;
    z-index: -1;
    background: gainsboro;
    transition: all 0.3s ease;
}

.shutdowndatasearchbutton:hover:after {
    left: auto;
    right: 0;
    width: 100%;
}

.shutdowndatasearchbutton:active {
    top: 2px;
}

.shutdowndataaddbutton {
    font-family: "AileronRegular";
    font-size: 14px !important;
    font-weight: bold !important;
    background-color: #CCCCCC !important;
    color: #29323a !important;
    border: none !important;
    height: 42px;
    padding: 5px 25px !important;
}

.shutdowndatadiv {
    overflow-x: auto;
    overflow-y: auto;
    white-space: nowrap;
    font-family: "AileronRegular" !important;
    font-size: 16px;
    min-width: 100%;
    height: 100vh;
}

.shutdowndatatable {
    background-color: white;
}

.shutdowndatatable table {
    table-layout: fixed;
    width: 100%;
}

.shutdowndatatable tr:not(:first-child) {
    height: 100px;
}

.shutdowndatatable thead th:nth-child(3) {
    position: sticky;
    position: -webkit-sticky;
    left: 0;
    z-index: 2;
}

.shutdowndatatable.shutdown-data thead th:nth-child(4) {
    position: sticky;
    position: -webkit-sticky;
    left: 10vw;
    z-index: 2;
}

.shutdowndatatable.shuttered-companies thead th:nth-child(6) {
    position: sticky;
    position: -webkit-sticky;
    left: 10vw;
    z-index: 2;
}

.shutdowndatatable tbody td:nth-child(3) {
    position: sticky;
    position: -webkit-sticky;
    left: 0;
    z-index: 1;
    background-color: white;
}

.shutdowndatatable.shutdown-data tbody td:nth-child(4) {
    position: sticky;
    position: -webkit-sticky;
    left: 10vw;
    z-index: 1;
    background-color: white;
}

.shutdowndatatable.shuttered-companies tbody td:nth-child(6) {
    position: sticky;
    position: -webkit-sticky;
    left: 10vw;
    z-index: 1;
    background-color: white;
}

.shutdowndatatable th {
    text-align: center;
    white-space: nowrap;
    padding: 8px;
    position: sticky;
    position: -webkit-sticky;
    top: 0;
    background: #e3e7f1;
    border-left: 1px solid #dee2e6;
    border-right: 1px solid #dee2e6;
}

.shutdowndatatable td {
    border: 1px solid #dee2e6;
}

.shutdowndatatable td:hover {
    .expand-button {
        color: black !important;
    }
}

.shutdowndatatable td:not(:first-child) {
    padding: 15px;
}

.shutdowndatafilters {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1;
    border-top: 2px solid #dee2e6;
    background: aliceblue;
    width: 100%;
    display: flex;
    flex-direction: row;
}

.filterrownumber {
    width: 30%;
    text-align: left;
}

.filterrownumber>p {
    margin: 1rem;
}

.filterpagination {
    width: 40%;
    text-align: center;
}

.filterpage,
.filterpage:hover {
    color: #039be5 !important;
    border: none !important;
    background: none !important;
    padding: 0 0.75rem !important;
    margin: 1rem 0 !important;
}

.filterrowshow {
    width: 30%;
    text-align: right;
    display: flex;
    flex-direction: row;
}

.filterrowshow>p {
    width: 50%;
    margin: 1rem 0;
}

.filterrowselect {
    width: 50% !important;
    margin: 0.6rem 0.75rem 0.4rem 0.75rem;
}

.shutteredlogo {
    width: 100px;
    height: auto;
    padding: 15px;
}

.shutteredlogo:hover {
    cursor: pointer;
}

.idealogo {
    width: 100px;
    height: auto;
    padding: 15px;
}

.investorlogo:hover {
    cursor: pointer;
}

.detailnote {
    padding-bottom: 20px;
}

.detailbodytitle {
    font-size: 14px;
    color: slategrey;
    margin-bottom: 0 !important;
}

.detailemptydata {
    text-align: center;
}

.detailxsdata {
    white-space: normal;
    width: 2vw;
    margin-bottom: 0;
    text-align: center;
}

.detailshortdata {
    white-space: normal;
    width: 8vw;
    margin-bottom: 0;
    text-align: center;
}

.detailmediumdata {
    white-space: normal;
    width: 10vw;
    margin-bottom: 0;
}

.detailmldata {
    white-space: normal;
    width: 15vw;
    margin-bottom: 0;
}

.detailml2data {
    white-space: normal;
    width: 18vw !important;
    margin-bottom: 0;
}

.detaillongdata {
    white-space: normal;
    width: 23vw;
    margin-bottom: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
}

.detailextralongdata {
    white-space: normal;
    width: 30vw;
    margin-bottom: 0;
}

.detailcutdata {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
}

.detailbodytitle {
    font-size: 14px;
    font-weight: bold;
    color: slategrey;
    margin-bottom: 0 !important;
}

.detailbodydata {
    font-size: 16px;
}

.detailproductlist {
    padding: 0 0 0 30px;
}

.detailproductlist>li:hover {
    background: none !important;
}

.progressbardivtable>div {
    height: 0.3rem;
    background-color: #eff8ff;
    border-radius: 0 !important;
}

.companies-header {
    height: 5px !important;
    border: none !important;
    padding: 1rem 1rem 2.4rem 1rem !important;
    /* margin-bottom: 1.5rem !important; */
}

.close {
    font-size: 1.3rem !important;
    border: none !important;
}

.companies-body {
    max-height: 90vh !important;
    overflow-y: auto !important;
    overflow-x: hidden;
}

.download-users-button {
    background-color: transparent !important;
    color: #039be5 !important;
    border: none !important;
    border-radius: 5px !important;
    padding-bottom: 11px !important;
}

.download-users-button:hover {
    background-color: #edeff2 !important;
}

@media only screen and (max-width: 475px) {
    .companies-body {
        max-height: 90vh !important;
        /* overflow-y: auto; */
    }
}

@media (max-width: 768px) {
    .detailxsdata {
        width: 6vw;
    }

    .detailshortdata {
        width: 24vw;
    }

    .detailmediumdata {
        width: 30vw;
    }

    .detailmldata {
        width: 45vw;
    }

    .detailml2data {
        width: 50vw;
    }

    .detaillongdata {
        width: 69vw;
    }

    .detailextralongdata {
        width: 90vw;
    }

    .shutdowndatafilters {
        flex-direction: column;
        font-size: 14px !important;
    }

    .shutdowndatahidefieldbutton {
        margin-left: 20px !important;
    }

    .filterrownumber,
    .filterpagination,
    .filterrowshow {
        width: 100%;
        text-align: center;
    }

    .filterrownumber>p {
        margin: 0.3rem 0 !important;
    }

    .filterpage,
    .filterpage:hover {
        font-size: 14px !important;
        margin: 0.3rem 0 !important
    }

    .filterrowshow>p,
    .filterrowselect {
        margin: 0.3rem 0 !important;
    }

    .companies-body {
        max-height: 90vh;
        /* overflow-y: auto; */
    }

    .shutdowndatasearchdiv {
        flex-direction: column;
    }

    .shutdowndatasearchbox {
        width: 100% !important;
        padding: 25px 50px 0 50px;
    }

    .shutdowndatasearchbuttons {
        width: 100% !important;
        padding: 0 50px 0 50px;
        text-align: end;
    }

    .shutdowndatadownloadbuttons {
        width: 100% !important;
        padding: 20px 50px 25px 50px;
    }

    .shutdownellipsisicon {
        margin-left: 40px !important;
    }

    .shutdowndataotherbuttons {
        text-align: left;
        width: 100%;
        padding: 1rem 50px 25px 50px;
    }
}
/* company-information.js */

#companyinformation .companydetailtitle {
  font-size: 14px;
  font-weight: bold;
  color: slategrey;
  margin-bottom: 0 !important;
}

#companyinformation .companydetaildata {
  font-size: 16px;
  margin-bottom: 1.5rem;
}

#companyinformation .companydetailrows {
  display: flex;
  flex-direction: row;
}

#companyinformation .companydetaildiv {
  width: 33%;
}

@media (max-width: 576px) {
  #companyinformation .companydetailrows {
    flex-direction: column;
  }

  #companyinformation .companydetaildiv {
    width: 100%;
  }
}

/* product-gallery.js */

#productgallery .companydetailproductdiv {
  text-align: center;
}

#productgallery .companydetailproductdiv>div>a {
  display: none;
}

#productgallery .companydetailproductimage {
  height: 500px;
}

/* products-services.js */

#productsservices .companydetailbodytitle {
  font-size: 14px;
  font-weight: bold;
  color: slategrey;
  margin-bottom: 0 !important;
}

#productsservices .companydetailbodydata {
  font-size: 16px;
}

#productsservices .companydetailproductlist {
  padding: 0 30px;
}

/* reasons-for-shutdown.js */

#reasonsforshutdown .companydetailbodytitle {
  font-size: 14px;
  font-weight: bold;
  color: slategrey;
  margin-bottom: 0 !important;
}

#reasonsforshutdown .companydetailbodydata {
  font-size: 16px;
}

#reasonsforshutdown .companydetailproductlist {
  padding: 0 30px;
}

#reasonsforshutdown .companydetailproductlist2 {
  padding: 0 18px;
}

#reasonsforshutdown .companydetailreasonlabel {
  font-weight: bold;
}

#reasonsforshutdown .companydetailsourceicon {
  margin-left: 5px;
  color: #039be5;
}

/* company detail tables */

#companydetail {
  width: 100%;
  background-color: #eff8ff;
  min-height: 100%;
  position: relative;
  font-family: "AileronRegular";
}

.companydetailtitlediv {
  width: 100%;
  padding: 50px;
}

.companydetailtext {
  font-family: "AileronRegular";
  text-align: left;
  font-size: 28px;
  font-weight: bold;
}

.companydetailtablediv {
  overflow-x: auto;
  overflow-y: auto;
  white-space: nowrap;
  font-family: "AileronRegular" !important;
  font-size: 16px;
  min-width: 100%;
  min-height: auto;
  max-height: 100vh;
}

.companydetailtable {
  background-color: white;
}

.companydetailtable table {
  table-layout: fixed;
  width: 100%;
}

.companydetailtable tr:not(:first-child) {
  height: 100px;
}

#directcompetitors .companydetailtable thead th:nth-child(3) {
  position: sticky;
  position: -webkit-sticky;
  left: 0;
  z-index: 2;
}

#directcompetitors .companydetailtable thead th:nth-child(4) {
  position: sticky;
  position: -webkit-sticky;
  left: 10vw;
  z-index: 2;
}

#directcompetitors .companydetailtable tbody td:nth-child(3) {
  position: sticky;
  position: -webkit-sticky;
  left: 0;
  z-index: 1;
  background-color: white;
}

#directcompetitors .companydetailtable tbody td:nth-child(4) {
  position: sticky;
  position: -webkit-sticky;
  left: 10vw;
  z-index: 1;
  background-color: white;
}

#financials .companydetailtable thead th:nth-child(2) {
  position: sticky;
  position: -webkit-sticky;
  left: 0;
  z-index: 2;
}

#financials .companydetailtable thead th:nth-child(3) {
  position: sticky;
  position: -webkit-sticky;
  left: 8vw;
  z-index: 2;
}

#financials .companydetailtable tbody td:nth-child(2) {
  position: sticky;
  position: -webkit-sticky;
  left: 0;
  z-index: 1;
  background-color: white;
}

#financials .companydetailtable tbody td:nth-child(3) {
  position: sticky;
  position: -webkit-sticky;
  left: 8vw;
  z-index: 1;
  background-color: white;
}

#investors .companydetailtable thead th:nth-child(3) {
  position: sticky;
  position: -webkit-sticky;
  left: 0;
  z-index: 2;
}

#investors .companydetailtable thead th:nth-child(4) {
  position: sticky;
  position: -webkit-sticky;
  left: 10vw;
  z-index: 2;
}

#investors .companydetailtable tbody td:nth-child(3) {
  position: sticky;
  position: -webkit-sticky;
  left: 0;
  z-index: 1;
  background-color: white;
}

#investors .companydetailtable tbody td:nth-child(4) {
  position: sticky;
  position: -webkit-sticky;
  left: 10vw;
  z-index: 1;
  background-color: white;
}

#management .companydetailtable thead th:nth-child(2) {
  position: sticky;
  position: -webkit-sticky;
  left: 0;
  z-index: 2;
}

#management .companydetailtable thead th:nth-child(3) {
  position: sticky;
  position: -webkit-sticky;
  left: 130px;
  z-index: 2;
}

#management .companydetailtable tbody td:nth-child(2) {
  position: sticky;
  position: -webkit-sticky;
  left: 0;
  z-index: 1;
  background-color: white;
}

#management .companydetailtable tbody td:nth-child(3) {
  position: sticky;
  position: -webkit-sticky;
  left: 130px;
  z-index: 1;
  background-color: white;
}

.companydetailtable th {
  text-align: center;
  white-space: nowrap;
  padding: 8px;
  position: sticky;
  position: -webkit-sticky;
  top: 0;
  background: #e3e7f1;
  border-left: 1px solid #dee2e6;
  border-right: 1px solid #dee2e6;
}

.companydetailtable td {
  border: 1px solid #dee2e6;
  padding: 15px;
}

.companydetailtable.data-room,
.companydetailtable.pitch-decks {
  width: 100%;
}

.companydetailtable.data-room thead tr th:first-child {
  width: 40vw !important;
}

.companydetailtable.data-room thead tr th {
  width: 12vw;
}

.companydetailnote {
  padding-bottom: 20px;
}

.companydetailbodytitle {
  font-size: 14px;
  color: slategrey;
  margin-bottom: 0 !important;
}

.companydetaillogo {
  width: 100px;
  height: auto;
  padding: 15px;
}

.companydetailemptydata {
  text-align: center;
}

.companydetailxsdata {
  white-space: normal;
  width: 2vw;
  margin-bottom: 0;
  text-align: center;
}

.companydetailshortdata {
  white-space: normal;
  width: 8vw;
  margin-bottom: 0;
  text-align: center;
}

.companydetailmediumdata {
  white-space: normal;
  width: 10vw;
  margin-bottom: 0;
}

.companydetailmldata {
  white-space: normal;
  width: 15vw;
  margin-bottom: 0;
}

.companydetaillongdata {
  white-space: normal;
  width: 23vw;
  margin-bottom: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

.companydetailextralongdata {
  white-space: normal;
  width: 30vw;
  margin-bottom: 0;
}

.companydetailcutdata {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 10;
  -webkit-box-orient: vertical;
}

.companydetailbodytitle {
  font-size: 14px;
  font-weight: bold;
  color: slategrey;
  margin-bottom: 0 !important;
}

.companydetailbodydata {
  font-size: 16px;
}

.companydetailproductlist li:hover {
  background: none !important;
}

.pitch-deck-viewer {
  margin-top: calc(var(--component-height) / -2);
  margin-bottom: 2em;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.react-pdf__Document {
  border-radius: 0.35rem !important;
  box-shadow: 0 23px 20px -20px rgb(0 0 0 / 80%), 0 0 15px rgb(0 0 0 / 76%);
}

.pitch-deck-pagination {
  display: flex;
  flex-direction: row;
  justify-content: center;
  border-radius: 0.5rem;
  background-color: rgb(0, 0, 0, 0.6);
  color: white;
  width: fit-content;
  position: fixed;
  bottom: 5vh;
  z-index: 2;
}

.pitch-deck-pagination>p {
  margin-bottom: 0;
  padding: 0 0.75rem !important;
}

.pitch-deck-page-button {
  color: white !important;
  border: none !important;
  background: none !important;
  padding: 0 0.75rem !important;
}

.pitch-deck-page-button:hover {
  text-decoration: underline;
}

.pitch-deck-page-button-disabled {
  color: darkgrey !important;
  border: none !important;
  background: none !important;
  padding: 0 0.75rem !important;
}

.textLayer {
  position: absolute;
  text-align: initial;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
  line-height: 1;
  text-size-adjust: none;
  forced-color-adjust: none;
  transform-origin: 0 0;
  z-index: 2;
}

.annotationLayer {
  position: absolute;
  top: 0;
  left: 0;
  pointer-events: none;
  transform-origin: 0 0;
}

@media (max-width: 576px) {
  .companydetailxsdata {
    width: 6vw;
  }

  .companydetailshortdata {
    width: 24vw;
  }

  #financials .companydetailtable thead th:nth-child(3) {
    position: sticky;
    position: -webkit-sticky;
    left: 24vw;
    z-index: 2;
  }

  #financials .companydetailtable tbody td:nth-child(3) {
    position: sticky;
    position: -webkit-sticky;
    left: 24vw;
    z-index: 1;
    background-color: white;
  }

  .companydetailmediumdata {
    width: 30vw;
  }

  #directcompetitors .companydetailtable thead th:nth-child(4),
  #investors .companydetailtable thead th:nth-child(4) {
    position: sticky;
    position: -webkit-sticky;
    left: 30vw;
    z-index: 2;
  }

  #directcompetitors .companydetailtable tbody td:nth-child(4),
  #investors .companydetailtable tbody td:nth-child(4) {
    position: sticky;
    position: -webkit-sticky;
    left: 30vw;
    z-index: 1;
    background-color: white;
  }

  .companydetailmldata {
    width: 45vw;
  }

  .companydetaillongdata {
    width: 69vw;
  }

  .companydetailextralongdata {
    width: 90vw;
  }
}
#company {
    width: 100%;
    background-color: #eff8ff;
    min-height: 120vh;
    position: relative;
    font-family: "AileronRegular";
}

.companyeditdiv {
    text-align: center;
    padding-top: 20px;
}

.companyedit {
    color: slategrey !important;
    height: 5px;
    margin-left: 5px;
}

.companyedit:hover {
    color: slategrey !important;
    text-decoration: underline;
}

.companycontent {
    padding: 80px 60px;
}

.companyheader {
    display: flex;
    padding-bottom: 80px;
}

.companylogodiv {
    display: flex;
    align-items: center;
}

.companylogo {
    width: 150px;
}

.companyheaderright {
    padding-left: 20px;
}

.companyheaderright>h3 {
    font-size: 30px;
}

.companyheaderright>p {
    font-size: 20px;
}

.companycardheader {
    display: flex;
    font-size: 18px;
    background-color: white !important;
    border-color: #cdcdcd !important;
    padding: .75rem 1.25rem;
    border-bottom: none;
}

.companyarrowicon {
    margin-top: -2px;
    padding-right: 10px;
}

.companyarrowiconright {
    margin-top: -2px;
    flex: auto;
    text-align: right;
}

.companytitlecard {
    color: #1f5f8b !important;
    font-weight: bold;
}

.companyellipsisicon {
    flex: auto;
    text-align: right;
}

.companytabs {
    font-size: 22px;
    border-bottom: 1px solid #cdcdcd !important;
}

.companytabs>a {
    background-color: transparent !important;
    color: #29323a !important;
    border-bottom: 1px solid #cdcdcd !important;
    white-space: nowrap;
}

.companytabs .nav-link.active {
    border-bottom: solid 1px black !important;
}

.companyaccordion {
    padding-top: 40px;
}

/* media breakpoints */
@media (max-width: 576px) {
    .companyheader {
        display: block;
    }

    .companylogodiv {
        display: block;
        padding-bottom: 20px;
    }

    .companyheaderright {
        padding: 0;
    }
}

@media (max-width: 1200px) {
    .companytabs {
        flex-wrap: nowrap !important;
        overflow-x: auto;
        overflow-y: hidden;
    }

    .companytabs::-webkit-scrollbar {
        height: 10px;
    }
}

.progressbardivtable>div {
    height: 0.3rem;
    background-color: #eff8ff;
    border-radius: 0 !important;
}
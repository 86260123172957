@import 'bootstrap/dist/css/bootstrap.css';

#nocodebuilder {
  width: 100%;
  background-color: #eff8ff;
  min-height: 120vh;
  position: relative;
  font-family: "AileronRegular";
}

#editor {
  /* width: 90% !important; */
  height: 900px !important;
  /* margin-left: 76px !important; */
  font-family: "AileronRegular";
}

/* body {
  font-family: "AileronRegular" !important;
  margin: 0;
  padding: 0;
}

html {
  overflow: scroll;
  overflow-x: hidden;
  -ms-overflow-style: none;
  scrollbar-width: none;
} */

.gjs-pn-views::-webkit-scrollbar,
.gjs-pn-views-container::-webkit-scrollbar {
  display: none;
}

/* To style grapesjs plugin editor, simply inspect the element from a browser and override default style */
/* Primary color for the background */

.gjs-editor {
  z-index: 1;
  font-family: "AileronRegular" !important;
}

.gjs-one-bg {
  background-color: #f0f8ff !important;
}

.gjs-two-color {
  color: #011e42 !important;
}

.gjs-pn-panel .gjs-pn-commands .gjs-one-bg .gjs-two-color {
  background-color: #f0f8ff !important;
}

/* .gjs-two-color
.gjs-pn-btn:hover{
  color: #c1ccd3 ;
} */

/* toolbar buttons */
.gjs-pn-commands {
  width: 80% !important;
  border-bottom: 2px solid rgba(0, 0, 0, .2);
}

/* all toolbar buttons */
.gjs-pn-buttons {
  padding: 5px !important;
  /* padding-bottom: 5px !important; */
}

.gjs-three-bg {
  background-color: grey !important;
  color: #f0f8ff !important;
}

/* block manager */
.gjs-four-color,
.gjs-four-color-h:hover {
  color: grey !important;
}

/* all blocks */
.gjs-blocks-c {
  padding: 5px !important;
}

/* all top panel buttons*/
.gjs-pn-btn {
  font-size: 19px !important;
  border-radius: 0.3em !important;
  margin-right: 10px !important;
  padding: 2px !important;
}

.gjs-pn-btn:hover {
  background-color: grey !important;
}

.gjs-cv-canvas {
  width: 80% !important;
  top: 5.7% !important;
  height: 94.5% !important;
}

.gjs-toolbar {
  left: 1000px !important;
}

.gjs-pn-views {
  border-bottom: 2px solid rgba(0, 0, 0, .2);
  right: 0;
  width: 20% !important;
  z-index: 4;
  overflow: scroll !important;
}

.gjs-pn-views-container {
  height: 100%;
  padding: 48px 0px 0 !important;
  right: 0;
  width: 20% !important;
  overflow: auto;
  box-shadow: 0 0 5px rgba(0, 0, 0, .2);
}

.gjs-pn-options {
  right: 20% !important;
  top: 0;
}

.gjs-layer-count {
  position: absolute;
  right: 37px !important;
  top: 7px !important;
}

.gjs-layer-move {
  position: absolute !important;
  width: 20px !important;
  padding: 5px 7px 2px 0px !important;
  top: 0;
  cursor: move;
}

.gjs-layer-vis {
  padding: 5px 1px 9px 4px !important;
  width: 20px !important;
}

.gjs-layer-caret {
  width: 20px !important;
  padding: 0px 2px 6px 2px !important;
}

/* data-tooltip */
.gjs-pn-buttons::after {
  font-family: "AileronRegular" !important;
  background: rgba(51, 51, 51, 0.9) !important;
  padding: 0.6rem 1rem !important;
}

/* color picker position*/
.sp-container sp-light sp-input-disabled sp-alpha-enabled sp-palette-buttons-disabled sp-initial-disabled gjs-one-bg gjs-two-color {
  position: sticky !important;
  top: 100px !important;
  left: 1332px !important;
}

/* add image button */
.gjs-btn-prim {
  border: 1px solid #011e42 !important;
  border-radius: 0.3em;
}

/* all blocks */
.gjs-block {
  cursor: grab !important;
  border-radius: 0.3em !important;
}

.gjs-block:hover {
  border: 2px solid transparent color indigo !important;
  transform: scale(1.05);
  will-change: transform;
}

/* blocks manager label */
.gjs-block-label {
  font-family: "AileronRegular" !important;
  font-size: 0.80rem !important;
}

.gjs-btn-prim:hover {
  background-color: #eff8ff !important;
}

/* blue line that highlights component */
.gjs-selected {
  outline: 2px solid #3b97e3 !important;
  background-color: black !important;
  outline-offset: -2px !important;
}

/* blue toolbar that highlights component when selected */
.gjs-toolbar {
  border-radius: 0.3em !important;
}

.gjs-toolbar-item:hover {
  border-radius: 0.3em !important;
  background-color: grey !important;
}

/* 'view components'  line */
.gjs-dashed [data-gjs-highlightable] {
  outline: 1.5px dashed !important;
  outline-color: aliceblue !important;
}

/* new sidebar for lists of pages */
/* page panel's icon */
.fa .fa-file {
  display: flex;
}

.pages {
  border-bottom: 1px;
  padding: 10px;
  background-color: rgba(0, 0, 0, .1);
  text-align: left;
  font-size: 12px;
}

.pages-inputbox {
  border-bottom: 1px;
  padding: 18px;
  text-align: left;
}

.pages-input {
  background-color: transparent;
  border-radius: 0.3em;
  /* margin-left: 20px; */
  padding: 5px;
}

.pages-btn {
  /* padding: 5px 25px;
  cursor: pointer; */
  transition: all 0.3s ease;
  position: relative;
  display: inline-block;
  width: 90px;
  line-height: 35px;
  padding: 0;
  border: none;
  margin-left: 5px;
}

.pages-list {
  border-bottom: 1px;
  text-align: left;
  font-size: 12px;
  display: flex;
  padding: 20px;
}

.pages-table {
  width: 100%;
  border-radius: 0.5em !important;
  /* background-color: rgba(0,0,0,.2) !important; */
  /* padding: 20px; */
}

.pages-th {
  background-color: #eff8ff;
  text-align: left;
  font-family: "AileronRegular";
  font-size: 14px;
  padding: 10px;
  border-bottom: 1px solid #dee2e6;
}

.pages-first-td {
  width: 5% !important;
  border-bottom: 1px solid #dee2e6;
  padding: 10px !important;
}

.pages-second-td {
  width: 50% !important;
  border-bottom: 1px solid #dee2e6;
  padding: 10px !important;
}

.pages-third-td {
  width: 20% !important;
  text-align: end !important;
  border-bottom: 1px solid #dee2e6;
  padding: 10px !important;
}

/* "custom code" & "typed" plugin */

.gjs-block__media {
  margin-bottom: 0px !important;
  font-size: 2em !important;
  line-height: 2em !important;
  padding: 0 40px !important;
}

/* save button in custom code component */

.gjs-btn-prim.gjs-btn-import__custom-code {
  padding: 6px 15px !important;
  margin-top: 1.0em !important;
  float: right !important;
  background-color: royalblue !important;
  color: white !important;
  font-size: 14px !important;
  border-radius: 5px !important;
  border: 1px solid royalblue !important;
  font-weight: bold !important;
  font-family: "AileronRegular";
}

.nocodeheaderdiv {
  width: 100%;
  display: flex;
  flex-direction: row;
}

.nocodetitlediv {
  width: 60%;
  padding: 50px 0px 50px 80px;
}

.nocodestatsdiv {
  font-family: "AileronRegular";
  width: 40%;
  padding: 70px 90px 50px 0px;
  margin-top: 13px;
  text-align: right;
}

.nocodetext {
  font-family: "AileronRegular";
  text-align: left;
  font-size: 28px;
  font-weight: bold;
}

.nocodecreatetext {
  font-family: "AileronRegular";
  text-align: left;
  font-size: 16px;
  font-weight: bold;
  color: #1f5f8b !important;
}

.nocodetitlefield {
  font-size: 28px !important;
  border-color: transparent !important;
  background-color: transparent !important;
}

/* save and publish button */

.save-btn {
  border: 1px solid rgba(0, 0, 0, .2) !important;
  border-radius: 0.3em !important;
  box-shadow: 0 1px 0 0 rgba(0, 0, 0, .15);
}

.publish-btn {
  border: 1px solid rgba(0, 0, 0, .2) !important;
  border-radius: 0.3em !important;
  box-shadow: 0 1px 0 0 rgba(0, 0, 0, .15);
}

.my-small-modal .gjs-mdl-dialog {
  max-width: 500px;
}

.nocodesavebutton {
  padding: 5px 25px;
  font-family: "AileronRegular";
  font-size: 14px;
  font-weight: bold;
  background: transparent;
  outline: none !important;
  cursor: pointer;
  transition: all 0.3s ease;
  position: relative;
  display: inline-block;
  border: 2px solid gainsboro;
  z-index: 1;
  margin-left: 20px;
}

.nocodesavebutton:after {
  position: absolute;
  content: "";
  width: 0;
  height: 100%;
  top: 0;
  right: 0;
  z-index: -1;
  background: gainsboro;
  transition: all 0.3s ease;
}

.nocodesavebutton:hover:after {
  left: 0;
  width: 100%;
}

.nocodesavebutton:active {
  top: 2px;
}

.nocodeclosebutton {
  padding: 5px 25px;
  background: transparent;
  outline: none !important;
  cursor: pointer;
  transition: all 0.3s ease;
  position: relative;
  display: inline-block;
  width: 90px;
  line-height: 35px;
  padding: 0;
  border: none;
  margin-left: 20px;
}

.nocodeclosebutton span {
  position: relative;
  display: block;
  width: 100%;
  height: 100%;
  font-family: "AileronRegular";
  font-size: 14px;
  font-weight: bold;
}

.nocodeclosebutton:before,
.nocodeclosebutton:after {
  position: absolute;
  content: "";
  height: 0%;
  width: 2px;
  background: gainsboro;
}

.nocodeclosebutton:before {
  right: 0;
  top: 0;
  transition: all 0.1s ease;
}

.nocodeclosebutton:after {
  left: 0;
  bottom: 0;
  transition: all 0.1s 0.2s ease;
}

.nocodeclosebutton:hover:before {
  transition: all 0.1s 0.2s ease;
  height: 100%;
}

.nocodeclosebutton:hover:after {
  transition: all 0.1s ease;
  height: 100%;
}

.nocodeclosebutton span:before,
.nocodeclosebutton span:after {
  position: absolute;
  content: "";
  background: gainsboro;
}

.nocodeclosebutton span:before {
  left: 0;
  top: 0;
  width: 0%;
  height: 2px;
  transition: all 0.1s 0.1s ease;
}

.nocodeclosebutton span:after {
  left: 0;
  bottom: 0;
  width: 100%;
  height: 2px;
}

.nocodeclosebutton span:hover:before {
  width: 100%;
}

.no-code-list-group-item {
  background-color: #f0f8ff !important;
  color: black !important;
}

.no-code-my-2 {
  padding-left: 1rem !important;
  padding-right: 1rem !important;
}

.glintanimation {
  position: relative;
}

.glintanimation:after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 0;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.4);
  -webkit-transition: none;
  -moz-transition: none;
  transition: none;
}

.glintanimation:hover:after {
  width: 120%;
  background-color: rgba(255, 255, 255, 0);
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
}

.nocodemaincontent {
  position: relative;
  width: 100%;
  /* z-index : 1; */
}

.nocodemaincontent .no-code-navbar {
  padding: 0;
}

.nocodemaincontent .no-code-navbar .no-code-container-fluid {
  padding: 0;
}

.editortooltip {
  font-family: "AileronRegular";
  text-transform: none;
  max-width: 500px;
}

/* style manager -> extra -> '+' button */
.gjs-sm-stack #gjs-sm-add {
  color: #011e42 !important;
}

/* style manager -> extra -> 'x' button */
.gjs-sm-clear {
  height: 19px !important;
}

/* style manager -> extra -> move button */
.gjs-sm-layer #gjs-sm-move {
  height: 19px !important;
}

/* style manager -> extra -> close button */
.gjs-sm-layer #gjs-sm-close-layer {
  height: 19px !important;
}

/* responsive screen */
/* @media only screen and (max-width: 800px) {

} */

/* intro.js position */

.highlight-canvas {
  width: 72% !important;
  height: 75% !important;
  top: 36% !important;
  left: 70px !important;
}

.tooltip-block-manager {
  margin-left: 475px !important;
  margin-top: 27px !important;
}

.highlight-block-manager {
  width: 2.5% !important;
  height: 5% !important;
  top: 31.5% !important;
  left: 87.3% !important;
}

.tooltip-screen-size {
  margin-left: -695px !important;
  margin-top: 10px !important;
}

.highlight-screen-size {
  width: 7% !important;
  height: 5% !important;
  top: 31.5% !important;
  left: 4% !important;
}

.tooltip-view-comp {
  margin-left: 125px !important;
  margin-top: 10px !important;
}

.highlight-view-comp {
  width: 2.5% !important;
  height: 5% !important;
  top: 31.5% !important;
  left: 54.4% !important;
}

.tooltip-layout {
  margin-left: 125px !important;
  margin-top: 10px !important;
}

.highlight-layout {
  width: 19% !important;
  height: 50% !important;
  top: 31.5% !important;
  left: 75.5% !important;
}

.highlight-i {
  width: 2.5% !important;
  height: 5% !important;
  top: 31.5% !important;
  left: 66.9% !important;
}

/* guided tour */

.introjs-tooltipReferenceLayer * {
  font-family: "AileronRegular" !important;
}

.introjs-button {
  font-size: 14px !important;
  font-weight: bold !important;
  background-color: gainsboro !important;
  color: #29323a !important;
  border: none !important;
  padding: 5px 25px !important;
  text-shadow: none;
  border-radius: 0.25rem;
}
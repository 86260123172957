#addnew {
    width: 100%;
    background-color: #eff8ff;
    min-height: 120vh;
    position: relative;
    font-family: "AileronRegular";
}

.addnewprogressbardivtable>div {
    height: 0.3rem;
    background-color: #eff8ff;
    border-radius: 0 !important;
}

.addnewprogressbardivfilters {
    padding-bottom: 1.25rem;
}

.addnewprogressbardivfilters>div {
    height: 0.3rem;
    background-color: white;
    border-radius: .35rem;
}

.addnewheaderdiv {
    width: 100%;
    display: flex;
    flex-direction: row;
}

.addnewtitlediv {
    width: 60%;
    padding: 50px 50px 50px 0;
}

.addnewtext {
    font-family: "AileronRegular";
    text-align: left;
    font-size: 28px;
    font-weight: bold;
}

.addnewstatsdiv {
    font-family: "AileronRegular";
    width: 40%;
    padding: 50px 0 50px 50px;
    margin-top: 13px;
    text-align: right;
}

.addnewsavebutton {
    padding: 5px 25px;
    font-family: "AileronRegular";
    font-size: 14px;
    font-weight: bold;
    background: transparent;
    outline: none !important;
    cursor: pointer;
    transition: all 0.3s ease;
    position: relative;
    display: inline-block;
    border: 2px solid gainsboro;
    z-index: 1;
    margin-left: 20px;
}

.addnewsavebutton:after {
    position: absolute;
    content: "";
    width: 0;
    height: 100%;
    top: 0;
    right: 0;
    z-index: -1;
    background: gainsboro;
    transition: all 0.3s ease;
}

.addnewsavebutton:hover:after {
    left: 0;
    width: 100%;
}

.addnewsavebutton:active {
    top: 2px;
}

.addnewclosebutton {
    padding: 5px 25px;
    background: transparent;
    outline: none !important;
    cursor: pointer;
    transition: all 0.3s ease;
    position: relative;
    display: inline-block;
    width: 90px;
    line-height: 35px;
    padding: 0;
    border: none;
    margin-left: 20px;
}

.addnewclosebutton span {
    position: relative;
    display: block;
    width: 100%;
    height: 100%;
    font-family: "AileronRegular";
    font-size: 14px;
    font-weight: bold;
}

.addnewclosebutton:before,
.addnewclosebutton:after {
    position: absolute;
    content: "";
    height: 0%;
    width: 2px;
    background: gainsboro;
}

.addnewclosebutton:before {
    right: 0;
    top: 0;
    transition: all 0.1s ease;
}

.addnewclosebutton:after {
    left: 0;
    bottom: 0;
    transition: all 0.1s 0.2s ease;
}

.addnewclosebutton:hover:before {
    transition: all 0.1s 0.2s ease;
    height: 100%;
}

.addnewclosebutton:hover:after {
    transition: all 0.1s ease;
    height: 100%;
}

.addnewclosebutton span:before,
.addnewclosebutton span:after {
    position: absolute;
    content: "";
    background: gainsboro;
}

.addnewclosebutton span:before {
    left: 0;
    top: 0;
    width: 0%;
    height: 2px;
    transition: all 0.1s 0.1s ease;
}

.addnewclosebutton span:after {
    left: 0;
    bottom: 0;
    width: 100%;
    height: 2px;
}

.addnewclosebutton span:hover:before {
    width: 100%;
}

.addnewmessagesave {
    font-size: 13px;
    font-weight: bold;
    font-family: "AileronRegular";
}

.addnewmessageclose {
    padding: 0 !important;
    margin-left: 5px;
    background-color: transparent !important;
    border: none !important;
    color: #155724 !important;
}

.addnewtabs {
    font-size: 22px;
    border-bottom: 1px solid #cdcdcd !important;
}

.addnewtabs>a {
    background-color: transparent !important;
    color: #29323a !important;
    border-bottom: 1px solid #cdcdcd !important;
}

.addnewscrollablediv {
    height: auto;
    overflow-y: auto;
}

.addnewaccordioncardbody {
    background: #f7f7f7;
    padding: 0 !important;
    overflow: auto;
}

.addnewaccordioncardbodydiv {
    padding-top: 1%;
    padding-bottom: 2%;
}

.addnewlongrow {
    padding-bottom: 30px;
}

.addnewlongfield {
    width: 100%;
    padding: 0 1%;
}

.addnewshortrow {
    padding-bottom: 30px;
    display: flex;
    flex-direction: row;
}

.addnewshortfield {
    width: 33%;
    padding: 0 1%;
}

.addnewprompt {
    font-size: 10.5px;
    padding: 0 1em;
    font-family: "AileronRegular";
}

.addnewdobinputbox {
    display: block;
    width: 100%;
    height: calc(1.5em + 0.75rem + 2px);
    padding: 0.375rem 0.75rem !important;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da !important;
    border-radius: 0.25rem;
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

/* text inside date picker */
.addnewdobinputbox .MuiInputBase-input {
    height: auto !important;
    padding: 0 !important;
    font-family: "AileronRegular" !important;
    font-size: 14px !important;
}

/* in edit form only */

.addnewbuttondiv {
    place-content: stretch center;
    align-items: stretch;
    flex-direction: row;
    box-sizing: border-box;
    display: flex;
    margin-top: 1.5rem;
    background-color: transparent !important;
}

.addnewbutton {
    font-weight: bold !important;
    background-color: #CCCCCC !important;
    color: #29323a !important;
    border: none !important;
    margin-right: 30px;
}

/* media breakpoints */

@media (max-width: 1200px) {
    .addnewtabs {
        flex-wrap: nowrap;
        overflow-x: auto;
        overflow-y: hidden;
    }

    .addnewtabs::-webkit-scrollbar {
        height: 10px;
    }

    .addnewtitlediv {
        width: 50%;
    }

    .addnewstatsdiv {
        width: 50%;
    }
}

@media (max-width: 768px) {
    .addnewshortrow {
        padding-bottom: 0;
        flex-direction: column;
        width: 100%;
    }

    .addnewshortfield {
        width: 100%;
        padding-bottom: 30px;
    }

    .addnewheaderdiv {
        flex-direction: column;
    }

    .addnewtitlediv {
        width: 100%;
        padding: 50px 0 25px 0;
    }

    .addnewstatsdiv {
        width: 100%;
        padding: 25px 0 50px 0;
        text-align: left;
    }
}
#profile {
    background-color: aliceblue;
    width: 100%;
    min-height: 120vh;
    margin: 0px;
    font-family: "AileronRegular";
}

.profilewidget {
    display: block;
    position: relative;
    margin-bottom: 30px;
    padding: 15px 20px;
    background: #fff;
    border-radius: 0.35rem;
    box-shadow: 0 23px 20px -20px rgba(0, 0, 0, 0.1), 0 0 15px rgba(0, 0, 0, 0.06);
    padding: 100px 50px;
}

.profiletext {
    font-family: "AileronRegular";
    text-align: left;
    font-size: 28px;
    font-weight: bold;
}
  
.profiletablenoborder {
    border-collapse: collapse;
}
  
.profiletablerow { 
    border: 1px solid #dee2e6;
    border-width: 1px 0;
    height: 50px;
    font-size: 16px;
}
  
.profiletablerow:first-child {
    border-top: none;
}
  
.profiletablerow:last-child {
    border-bottom: none;
}
  
.profiletabledata:first-child {
    width: 30vw;
}
  
.profiletabledata:last-child {
    width: 10vw;
}
  
.profiletablecolmiddle {
    width: 50vw;
}

/* edit profile css starts */

.editprofilecard {
    width: 32% !important;
}

.editprofilewidget {
    display: block;
    position: relative;
    margin-bottom: 30px;
    padding: 15px 20px;
    background: #fff;
    border-radius: 0.35rem;
    box-shadow: 0 23px 20px -20px rgba(0, 0, 0, 0.1), 0 0 15px rgba(0, 0, 0, 0.06);
    padding: 40px;
}

.dobinputbox {
    display: block;
    width: 100%;
    height: calc(1.5em + 0.75rem + 2px);
    padding: 0.375rem 0.75rem !important;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da !important;
    border-radius: 0.25rem;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}

/* text inside date picker */
.dobinputbox .MuiInputBase-input {
    height: auto !important;
    padding: 0 !important;
    font-family: "AileronRegular" !important;
    font-size: 14px !important;
}

@media only screen and (max-width: 425px) {
    .profiletablerow {
        font-size: 12px;
    }

    .editprofilecard {
        width: 80% !important;
    }
}

@media only screen and (max-width: 600px) {
    .profiletabledata:first-child {
        width: 20vw;
    }
}

@media only screen and (min-width: 600px) and (max-width: 950px) {
    .profiletabledata:first-child {
        width: 20vw;
    }
}

@media only screen and (min-width: 951px) and (max-width: 1024px) {
    .profiletabledata:first-child {
        width: 20vw;
    }
}

@media only screen and (min-width: 426px) and (max-width: 576px) {
    .editprofilecard {
        width: 70% !important;
    }
}

@media only screen and (min-width: 577px) and (max-width: 768px) {
    .editprofilecard {
        width: 50% !important;
    }
}

@media only screen and (min-width: 769px) and (max-width: 1024px) {
    .editprofilecard {
        width: 40% !important;
    }
}
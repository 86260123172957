body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif, 'AileronThin', 'AileronThinItalic';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: aliceblue !important;

  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

* {
  box-sizing: border-box;
}

@import url('https://fontlibrary.org/face/aileron');

@font-face {
  font-family: "AileronThinItalic";
  src: url("./assets/fonts/aileron/Aileron-ThinItalic.eot");
  src: url("./assets/fonts/aileron/Aileron-ThinItalic.eot?#iefix") format("embedded-opentype"), url("./assets/fonts/aileron/Aileron-ThinItalic.woff2") format("woff2"),
    url("./assets/fonts/aileron/Aileron-ThinItalic.woff") format("woff"), url("./assets/fonts/aileron/Aileron-ThinItalic.ttf") format("truetype"),
    url("./assets/fonts/aileron/Aileron-ThinItalic.svg#Aileron-ThinItalic") format("svg");
  font-weight: 100;
  font-style: italic;
}
@font-face {
  font-family: "AileronThin";
  src: url("./assets/fonts/aileron/Aileron-Thin.eot");
  src: url("./assets/fonts/aileron/Aileron-Thin.eot?#iefix") format("embedded-opentype"), url("./assets/fonts/aileron/Aileron-Thin.woff2") format("woff2"),
    url("./assets/fonts/aileron/Aileron-Thin.woff") format("woff"), url("./assets/fonts/aileron/Aileron-Thin.ttf") format("truetype"),
    url("./assets/fonts/aileron/Aileron-Thin.svg#Aileron-Thin") format("svg");
  font-weight: 100;
  font-style: italic;
}

@media print {
  body {
    display: none;
  }
}